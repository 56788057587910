import React, { useEffect, useState} from "react";
import { Modal, Button, InputGroup, Form } from 'react-bootstrap';
import { useGlobalState } from "../../config/routes";
import "./WelcomeModal.css";

const WelcomeModal = () => {
    const { username, setUsername, show, setShow, imOkay, setOkay} = useGlobalState(); // Access global state and methods

    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setInputValue(username); // Set input value to username
        imOkay ? setShow(false) : setShow(true); 
    }, [username, imOkay, setShow]);

    const handleClose = () => {
        setShow(false); // Close the modal
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleOkayClick = () => {
        // setUsername('___________'); // Set a default value
        localStorage.setItem('username', '___________');
        setShow(false);

        setOkay(true);  
        localStorage.setItem('imOkay', true);
        // window.location.reload();
        console.log(imOkay);
    };

    
    const handleSaveClick = () => {
        setUsername(inputValue); // Set the value entered in the input field
        localStorage.setItem('username', inputValue);
        localStorage.setItem('imOkay', false);
        setShow(false);
    };
    return (
        <Modal show={show} onHide={handleClose} animation={false} className="friendly_modal">
            <Modal.Header closeButton>
                <Modal.Title>Welcome!</Modal.Title>
            </Modal.Header>
            <Modal.Body>How would you like me to refer to you?</Modal.Body>
            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                <Form.Control
                    placeholder="Name/Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={inputValue}
                    onChange={handleInputChange}
                />
            </InputGroup>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleOkayClick}>
                    I'm Okay~
                </Button>
                <Button variant="dark" onClick={handleSaveClick}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default WelcomeModal;
