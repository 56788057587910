import React from 'react';
import { Col, Button, Container, Row} from "react-bootstrap"
import StarIcon from "../../../assets/icons/star.png"

import "./SecondRow.css"

// import CleanResume from "../../../assets/docs/Clean Resume.pdf"

const SplitColumns = () => {
    
      return (
          <div className='whatOffered' >
            {/* sticky_Second */}
            <Container fluid>
                <Row>
                    <Col className='col-md-6 col-12' style={{backgroundColor: "rgba(0, 0, 0, 0.2)"}}>
                    <Row className='mt-5'>
                        <h1 className=' d-flex justify-content-end text-primary font-weight-bold'>
                            What We Offer
                        </h1>
                    </Row>
                    </Col>
                    <Col className='col-md-6 col-12 mb-5'>
                    <Row>
                        <Col className='column_space_inbetween'>
                            <div>
                                <img src={StarIcon} className='fit_icons'/>
                                <h3>SELF DEVELOPMENT</h3>
                            </div>
                            
                            <p>Grow your mindset/skillset. Get educated on entrepreneurship. </p>

                            <div>
                                <img src={StarIcon} class="fit_icons" alt="Star Icon"/>
                                <h3>BUSINESS ASSOCIATION</h3>
                            </div>
                            <p>We host multiple networking events to discuss about success around US & Canada</p>

                            <div>
                                <img src={StarIcon} class="fit_icons" alt="Star Icon"/>
                                <h3>MENTORSHIP SYSTEM</h3>
                            </div>
                            <p>Open doors to meet most successful business mentors in America who have been created multi-million/billion dollar e-commerce business</p>
                        </Col>
                        <Col className='column_space_inbetween'>
                            <div>
                                <img src={StarIcon} class="fit_icons" alt="Star Icon"/>
                                <h3>SUCCESS WORKSHOPS</h3>
                            </div>
                            <p>We host multiple networking events to discuss about success around US & Canada</p>

                            <div>
                                <img src={StarIcon} class="fit_icons" alt="Star Icon"/>
                                <h3>SUCCESS WORKSHOPS</h3>
                            </div>
                            <p>Our biggest once a year conference with more than 35000 participants every single year where you can get to learn from top entrepeneurs accross the globe. </p>

                            <div>
                                <img src={StarIcon} class="fit_icons" alt="Star Icon"/>
                                <h3>SIDE HUSTLE</h3>
                            </div>
                            <p>Opportunity to create passive income beside your full-time career. Helping you to accomplish financial independent.</p>
                        </Col>
                    </Row>
                    </Col>
                </Row>
                </Container>
  
          </div>
      )
    };

export default SplitColumns;
