import React from "react";
import { Container,  Navbar, } from 'react-bootstrap';
import './Footer.css';

const Footer = () => {

    return( 
      <div className='footer d-flex justify-content-center' >
        <Navbar  data-bs-theme="dark">
        <Container >
        {/* <Navbar.Brand href="#welcome" onClick={toggleUSERNAME}> */}
          <Navbar.Brand href="#footer"  style={{"fontSize":'1.5cap'}}>   
                &copy; Developed By&nbsp;
                <a href="https://nathanscorner.vercel.app">
                Nathan Diaz
                </a> 2024         
          </Navbar.Brand>
          
        </Container>
      </Navbar>
      </div>
    );
};

export default Footer;
