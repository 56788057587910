import { Container, Row, Col, Button, Carousel, Modal} from "react-bootstrap";
import { useState } from "react";
import MD_logo from '../../../assets/img/Million Dreams Logo-Negative.png'
import "./ForthOverlay.css";
import Event0 from "../../../assets/img/Events/event_0.jpg"
import Event1 from "../../../assets/img/Events/event_1.jpg"
import Event2 from "../../../assets/img/Events/event_2.jpg"
import Event3 from "../../../assets/img/Events/event_3.jpg"

const eventImages = [Event0, Event1, Event2, Event3];

const ForthOverlay = () => {
    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust transparency by changing the alpha value (0.5 means 50% transparent)
         zIndex: 2000, // Ensure the overlay is above the video player
    //   pointerEvents: 'none', // Allow interactions to pass through the overlay
    };

    const [show, setShow] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const handleModalClose = () => setShow(false);
    const handleModalOpen = () => 
        
        {
            setShow(true)
            setShowLoader(true)
            handleLoaderTemp()
        };

    const handleLoaderTemp = () => {
        
        setTimeout(() => {
            setShowLoader(false);
        }, 2500);
    };
  
    return (
        <div style={overlayStyle}>

            <Container id="our_values_fourth" className="d-flex flex-column justify-content-center align-items-center" >
            {/* justify-content-between vh-100*/}
                <Row className="w-100 text-center mt-5">
                    <h1 className="title_values">Featuring Event</h1>
                </Row>
                
                <Row className="w-100">
                    <Col className="text-center">
                        <h1 >
                        GET YOUR SLOT CONFIRM NOW!
                        </h1>
                    </Col>
                </Row>
                <Row className="d-flex  mt-5" >
                    <Col variant="col-12">
                    <Carousel>
                        {
                            eventImages.map((eventImage, index) =>(
                                <Carousel.Item key={index}>
                                    <img src={eventImage} 
                                        className="d-block mx-auto carousel-image" 
                                        alt={`Event ${index + 1}`}
                                        onClick={handleModalOpen}>
                                    </img>
                                </Carousel.Item>
                            ))
                        };
                        {/* <Carousel.Item>
                         
                            <img src={Event0} className="d-block mx-auto carousel-image"></img>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={Event1} className="d-block mx-auto carousel-image"></img>
                        </Carousel.Item>
                        <Carousel.Item>
                    
                            <img src={Event2} className="d-block mx-auto carousel-image"></img>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={Event3} className="d-block mx-auto carousel-image"></img>
                        </Carousel.Item> */}
                        </Carousel>
                    </Col>
                </Row>

                

            </Container>
            <Modal show={show} onHide={handleModalOpen} style={{zIndex:'10000', objectFit:"cover"}}>
                <Modal.Header>
                {/* <Modal.Header closeButton> */}
                    <Modal.Title>Ready to come?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body  style={{backgroundColor:"#FFCC99"}}>
                    <p style={{ display: showLoader ? 'block' : 'none' }}>Form's Loading~</p>
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScuWG8dbejf6BkTVKwXjcYZYpdNAAcbU0mtzMIJEDpTdbV8fQ/viewform?embedded=true" width="100%" height="918" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="outline-success" onClick={handleModalClose}>
                        Done!
                    </Button>
                    </Modal.Footer>
                </Modal>
        </div>
    )
  };

  export default ForthOverlay;