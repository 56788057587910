import { Container, Row, Col, Button} from "react-bootstrap";
import MD_logo from '../../../assets/img/Million Dreams Logo-Negative.png'
import "./ThirdOverlay.css";

const ThirdOverlay = () => {
    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust transparency by changing the alpha value (0.5 means 50% transparent)
        //  zIndex: 2000, // Ensure the overlay is above the video player
      pointerEvents: 'none', // Allow interactions to pass through the overlay
    };
  
    return (
        <div style={overlayStyle}>

            <Container className="d-flex flex-column justify-content-center align-items-center mt-md-5" id="our_values">
            {/* justify-content-between vh-100*/}
                <Row className="w-100 text-center ">
                    <h1 className="title_values">OUR VALUES</h1>
                </Row>
                <Row className="border-buttons-0 mt-md-5 mt-4" >
                    <Col variant="col-4" className="mx-0 my-2 mx-md-4 d-flex justify-content-center">
                        <Button className="button_width" variant="light" size="lg">FAMILY</Button>
                    </Col>
                    <Col variant="col-4" className="mx-0 my-2 mx-md-4 d-flex justify-content-center">
                        <Button className="button_width" variant="light" size="lg">HOPE</Button>
                    </Col>
                    <Col variant="col-4" className="mx-0 my-2 mx-md-4 d-flex justify-content-center">
                        <Button className="button_width" variant="light" size="lg">FREEDOM</Button>
                    </Col>
                    <Col variant="col-4" className="mx-0 my-2 mx-md-4 d-flex justify-content-center">
                        <Button className="button_width" variant="light" size="lg">REWARD</Button>
                    </Col>
                    {/* <Col xs="3" md="3" className="mx-0 my-2 mx-md-4 d-flex justify-content-center">
                        <Button variant="light" size="">REWARD</Button>
                    </Col> */}
                </Row>
                <Row className="w-100">
                    <Col className="text-center">
                        <h1 id="quoted_text">
                        "Success is not about how much money you have, it's about how many lives get better off because you live"
                        </h1>
                    </Col>
                </Row>

            </Container>

        </div>
    )
  };

  export default ThirdOverlay;