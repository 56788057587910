import { Container, Row, Col } from "react-bootstrap";
import MD_logo from '../../../assets/img/Million Dreams Logo-Negative.png'
import "./VideoTransparentOverlay.css";

const VideoTransparentOverlay = () => {

  
    const overlayStyle = {
        pointerEvents: 'none', // Allow interactions to pass through the overlay
      };

    return (
        <div className="VTO_Main" style={overlayStyle}>

            <Container className="d-flex flex-column  justify-content-center" style={{ height: '100%'}}>
                <div className="d-flex justify-content-end">
                    <img src={MD_logo} className="MD_logo_transparent"/>
                </div>
                <div className="d-flex flex-column millionDreams_text" >
                    {/* <h1 className="d-flex justify-content-end dis">MILLION DREAMS</h1> */}
                    <h1 className="d-flex justify-content-end non_dis">MILLION</h1>
                    <h1 className="d-flex justify-content-end non_dis">DREAMS</h1>
                    <p className="d-flex justify-content-end millionDreams_text_p">Business Association - Mentorship System - E-Commerce</p>
                </div>
                
                
                {/* <p>aoidmasdoiasndasodiasndoasdinasdoasidndaoinaskdmas</p> */}
            </Container>

        </div>
    )
  };

  export default VideoTransparentOverlay;