import React, {useEffect } from 'react';
import './App.css';
// import Loader from './components/loader/loader';
import Routes from './config/routes';


// import Nathan_Logo from '../src/assets/special/favicon_package_v0.16/mstile-150x150_white.png';
import './components/loader/loader.css';

import { SpeedInsights } from "@vercel/speed-insights/react"

const App = () => {


  return (
    <div className='hidden-scrollbar' id="home">
      <Routes />
      {/* <div id="loader-page" className="loader-page"><img id="img-loader" src={Nathan_Logo} alt='loader'/></div> */}
      {/* <Loader /> */}
    </div>
  );
}

export default App;
