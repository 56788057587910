import React  from "react";
import { Container, Nav, Navbar, NavDropdown, Popover, OverlayTrigger, Button} from 'react-bootstrap';
import './Banner.css';
import WelcomeModal from "../WelcomeModal/WelcomeModal";
import { useGlobalState } from "../../config/routes";
import NavBarIcon from "../../assets/special/favicon_MD/android-chrome-192x192.png"
// C:\Users\jondi\OneDrive\Comp\2-Github\MillionDreams\src\assets\special\favicon_MD

const Banner = () => {
  const { username, show, setShow, setOkay} = useGlobalState(); // Access global state and methods

  const handleUserChange = () => {
      setOkay(false)
      localStorage.setItem('imOkay', true);
      localStorage.setItem('username', '');
      setShow(true); // Show the modal
  };

    return( 
      <div className='banner'>
        <Navbar expand="lg" bg="dark" data-bs-theme="dark">
        <Container>
        {/* <Navbar.Brand href="#welcome" onClick={toggleUSERNAME}> */}
            <OverlayTrigger trigger="hover" placement="bottom" overlay={
                        <Popover id="popover-basic">
                            {/* <Popover.Header as="h3">Hi there!</Popover.Header> */}
                            <Popover.Body>Thanks for Visiting~</Popover.Body>
                        </Popover>
                    }>
                      <img
                        src={NavBarIcon}
                        alt="React Bootstrap logo"
                        className="img_banner"
                      />
              </OverlayTrigger>

          <Navbar.Brand href="#welcome" onClick={handleUserChange} >
                      
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
            {/* HOME */}
            <Nav.Link href="#home">HOME</Nav.Link>
              <OverlayTrigger trigger="hover" placement="bottom" overlay={
                        <Popover id="popover-basic">
                            <Popover.Body>In Development</Popover.Body>
                        </Popover>
                    }>
                      <Nav.Link href="#opportunities">OPPORTUNITIES</Nav.Link>
                      
              </OverlayTrigger>
              {/* OUR VALUES */}
              {/* <OverlayTrigger trigger="hover" placement="bottom" overlay={
                        <Popover id="popover-basic">
                            <Popover.Body>In Development</Popover.Body>
                        </Popover>
                    }> */}
                      <Nav.Link href="#our_values" >OUR VALUES</Nav.Link>
                      
              {/* </OverlayTrigger> */}
              {/* THE TEAM */}
              <OverlayTrigger trigger="hover" placement="bottom" overlay={
                        <Popover id="popover-basic">
                            <Popover.Body>In Development</Popover.Body>
                        </Popover>
                    }>
                      <Nav.Link href="#the_team" >THE TEAM</Nav.Link>
                      
              </OverlayTrigger>
              {/* CONTACT      */}
              <OverlayTrigger trigger="hover" placement="bottom" overlay={
                        <Popover id="popover-basic">
                            <Popover.Body>In Development</Popover.Body>
                        </Popover>
                    }>
                      <Nav.Link href="#contact" >CONTACT</Nav.Link>
                      
              </OverlayTrigger>
              {/* BUSINESS CENTER      */}
              <OverlayTrigger trigger="hover" placement="bottom" overlay={
                        <Popover id="popover-basic">
                            <Popover.Body>In Development</Popover.Body>
                        </Popover>
                    }>
                      <Nav.Link href="#business_center" >BUSINESS CENTER</Nav.Link>
                      
              </OverlayTrigger>
                      
                      
                      
                      
              
              {/* <Nav.Link href="#link">Portafolio</Nav.Link> */}
              
              {/* <NavDropdown title="Portafolio" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.3">
                  Current Projects
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Future Projects
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
        {/* Introductory Modal */}
            {/* <WelcomeModal username={username} setShow={setShowModal} setUsername={setUsername}/> */}
            {show && <WelcomeModal />}
      </Navbar>
      </div>
    );
};

export default Banner;
