import React, {useRef}from 'react';
import { Col, Button, Container, Row} from "react-bootstrap"
import StarIcon from "../../../assets/icons/star.png"


import { Player, BigPlayButton, ControlBar} from 'video-react';

import "./ThirdRow.css"
import ThirdOverlay from './ThirdOverlay';

// import CleanResume from "../../../assets/docs/Clean Resume.pdf"

const ThirdRow = (videoSet) => {

    console.log(Object.values(videoSet)[0]);
    const TR_bg_video_ref = useRef(null);
    
      return (
        <div id='bg_video'>
            {/* sticky_Second */}
            {/* <Container fluid> */}
            <Player
                        style={{pointerEvents: 'none', zIndex: -100}}
                        ref={TR_bg_video_ref}
                        autoPlay
                        muted
                        loop
                        fluid={true} // setting fluid to false ensures the video maintains its original size
                        src={Object.values(videoSet)[0]}
                        id="video_background_third"
                        >
                        
                        
                            <ControlBar disableCompletely={true} muted={true} />
                        </Player>
                        <ThirdOverlay/>
            {/* </Container> */}
  
          </div>
      )
    };

export default ThirdRow;
