import React, {useRef, useState}from 'react';
import ForthOverlay from "./ForthOverlay"

import { Player, BigPlayButton, ControlBar} from 'video-react';

import "./ForthRow.css"

// import CleanResume from "../../../assets/docs/Clean Resume.pdf"

const ForthRow = (videoSet) => {



    console.log(Object.values(videoSet)[0]);
    const TR_bg_video_ref = useRef(null);
    
      return (
        <div id='forth_row'>
            {/* sticky_Second */}
            {/* <Container fluid> */}
            <Player
                        // style={{pointerEvents: 'none', zIndex: -100}}
                        ref={TR_bg_video_ref}
                        autoPlay
                        muted
                        loop
                        fluid={true} // setting fluid to false ensures the video maintains its original size
                        src={Object.values(videoSet)[0]}
                        id="video_background_forth"
                        >
                        
                        
                        

                            {/* <BigPlayButton position="center" hidden/> */}
                            <ControlBar disableCompletely={true} muted={true} />
                        </Player>
                        <ForthOverlay/>
                {/* </Container> */}
  
          </div>
      )
    };

export default ForthRow;
