import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Button } from 'react-bootstrap'
import './MillionDreamsLanding.css';

import MD_bg_video from '../../assets/video/MillionDreamsLanding.mp4'
import TR_bg_video from '../../assets/video/Third Row.mp4'
import FR_bg_video from '../../assets/video/Forth Row.mp4'
import { Player, BigPlayButton, ControlBar} from 'video-react';
import 'video-react/dist/video-react.css';
// import './Banner.css';


import Banner from '../Banner/Banner';
import VideoTransparentOverlay from './VideoTransparentOverlay/VideoTransparentOverlay'
import SplitColumns from './SecondRow/SplitColumns';
import ThirdRow from './ThirdRow/ThirdRow';
import ForthRow from './ForthRow/ForthRow';

import Foot from '../footer_path/Footer';
import MainDescription from './MeDescription';
import RightUpperCarousel from './RightUpperCarousel';
import RightUpperPanel from './RightUpperPanel';
import LeftUpperCarousel from './LeftUpperCarousel';
import LeftUpperPanel from './LeftUpperPanel'

import CenterColumn from './SecondRow/CenterColumn';
import LeftLowerPanel from './SecondRow/LeftLowerPanel';
import RightLowerPanel from './SecondRow/RightLowerPanel';




// import { Helmet } from 'react-helmet';

import { useGlobalState } from '../../config/routes'; // Import the custom hook
import CalendlyWidget from '../Calendly/Calendly';
import { isVisible } from '@testing-library/user-event/dist/utils';

const MillionDreamsLanding = (props) => {
    const { username, setUsername, show, setShow,  imOkay, isBusinessVisible, setBusinessVisibility} = useGlobalState(); // Access global state and methods

    const MD_bg_video_ref = useRef(null);


    const loaded = () => {
        // console.log(startDateTime);
    };


    return (
        <>
            {/* CREATE A FADING ANIMATION, IN AND OUT FROM LEFT TO RIGHT TO SHOW A NEW VIEW */}

            <Banner username={username} show={show}/>
            {/* <Button  onClick={toggleVisibility}>Toggle Visibility</Button> */}
            <div className='content_wrapper' id="home" >
            {/* <Banner username={username} show={show}/> */}
            <div id='main_view' onLoad={loaded()} >
            {/* <Banner username={username} show={show}/> */}
            
                <Player
                        style={{pointerEvents: 'none', zIndex: -100}}
                        ref={MD_bg_video_ref}
                        autoPlay
                        muted
                        loop
                        fluid={true} // setting fluid to false ensures the video maintains its original size
                        src={MD_bg_video}
                        id="video_background"
                        >
                        
                        
                        

                            {/* <BigPlayButton position="center" hidden/> */}
                            <ControlBar disableCompletely={true} muted={true} />
                        </Player>
                        <VideoTransparentOverlay />
            </div>
            
            
            {/* SECOND ROW */}
            {/* <div className='sticky_Second'> */}
            <SplitColumns/>
            <ThirdRow videoSet={TR_bg_video}/>
            <ForthRow videoSet={FR_bg_video}/>

            {/* </div> */}

            <Foot />
                
            </div>
            
        </>

    );
};

export default MillionDreamsLanding;
