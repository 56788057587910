import React, { useState, useEffect, createContext, useContext } from "react";
// import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import MillionDreamsLanding from "../components/MillionDreamsLanding/MillionDreamsLanding";


const GlobalContext = createContext();

const N_Routes = () => {
  const [username, setUsername] = useState("inDevelopment");
  const [imOkay, setOkay] = useState((localStorage.getItem("imOkay")));
  const [isBusinessVisible, setBusinessVisibility] = useState(window.location.hash == "#biz");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [show, setShow] = useState(false);


  const globalState = {
    username,
    setUsername,
    isAuthenticated,
    setIsAuthenticated,
    show,
    setShow,
    imOkay,
    setOkay,
    isBusinessVisible,
    setBusinessVisibility

  };
  
  return (
    <GlobalContext.Provider value={globalState}>
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={<MillionDreamsLanding/>}
        />
      </Routes>
    </Router>
    </GlobalContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalContext);
export default N_Routes;
